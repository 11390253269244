import React, {
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { Snackbar, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import apiClient from "./apiClient";
import AuthContext from "./auth-context";

const AutoLogoutContext = createContext();

export const AutoLogoutProvider = ({ children, timeout = 30 * 60 * 1000 }) => {
    const { dispatch, state } = useContext(AuthContext);

    const timer = useRef(null);
    const countdownInterval = useRef(null);
    const navigate = useNavigate();
    const [showWarning, setShowWarning] = useState(false);
    const [remainingTime, setRemainingTime] = useState(timeout); // Initial remaining time
    const [snackbarMessage, setSnackbarMessage] = useState("");

    const logout = async () => {
        setShowWarning(false);
        console.log("User has been logged out due to inactivity.");
        try {
            await apiClient.post("/api/Account/logout");
            setTimeout(() => {
                dispatch({ type: "LOGOUT" });
                navigate("/");
            }, 2000);
        } catch (error) {
            console.error("Logout failed", error);
        }
    };

    const resetTimer = () => {
        if (timer.current) clearTimeout(timer.current);
        if (countdownInterval.current) clearInterval(countdownInterval.current);

        setRemainingTime(timeout);
        setShowWarning(false); // Hide Snackbar when timer is reset

        if (!state.isAuthenticated) return; // Do nothing if user is not authenticated

        // Start countdown
        countdownInterval.current = setInterval(() => {
            setRemainingTime((prev) => {
                if (prev <= 1000) {
                    clearInterval(countdownInterval.current);
                    return 0;
                }

                const timeLeft = prev - 1000;

                // Log remaining time in the console
                // console.log(
                //     `Remaining time before logout: ${Math.ceil(
                //         timeLeft / 1000
                //     )} seconds`
                // );

                // Show Snackbar for the last 10 seconds
                if (timeLeft <= 10 * 1000) {
                    setShowWarning(true);
                    setSnackbarMessage(
                        `You've been inactive for a while, Auto logout in ${Math.ceil(
                            timeLeft / 1000
                        )} seconds`
                    );
                }

                return timeLeft;
            });
        }, 1000);

        // Logout after the full timeout
        timer.current = setTimeout(() => {
            logout();
        }, timeout);
    };

    useEffect(() => {
        const activityEvents = ["mousemove", "keydown", "click", "scroll"];

        if (state.isAuthenticated) {
            console.log("User is authenticated. Auto logout is enabled.");

            // Attach activity listeners
            activityEvents.forEach((event) =>
                window.addEventListener(event, resetTimer)
            );

            resetTimer(); // Initialize the timer when authenticated
        } else {
            console.log("User is not authenticated. Auto logout is disabled.");
        }

        return () => {
            // Cleanup listeners and timers
            activityEvents.forEach((event) =>
                window.removeEventListener(event, resetTimer)
            );
            clearTimeout(timer.current);
            clearInterval(countdownInterval.current);
        };
    }, [state.isAuthenticated]); // Re-run effect when authentication state changes

    return (
        <AutoLogoutContext.Provider value={{ resetTimer }}>
            {children}
            <Snackbar
                open={showWarning}
                message={snackbarMessage} // Show dynamic message
                // action={
                //     <Button
                //         color="secondary"
                //         size="small"
                //         onClick={() => {
                //             setShowWarning(false); // Hide Snackbar
                //             resetTimer(); // Extend session
                //         }}
                //     >
                //         Stay Logged In
                //     </Button>
                // }
            />
        </AutoLogoutContext.Provider>
    );
};

export const useAutoLogout = () => {
    return useContext(AutoLogoutContext);
};
