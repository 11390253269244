import React, { useState, useEffect } from "react";
import { HubConnectionBuilder } from "@microsoft/signalr";
import {
    Container,
    Paper,
    Select,
    MenuItem,
    TextField,
    Button,
    Grid,
    Typography,
    Box,
    FormControl,
    InputLabel,
    CircularProgress,
    Snackbar,
    Alert,
    Collapse,
    Fade,
} from "@mui/material";
import ForgeViewer from "./forgeViewer";
import apiClient from "../../Helpers/apiClient";
import { useLocation, useNavigate } from "react-router-dom";

const DesignAutomation = () => {
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "success",
    });

    const [showCreateProject, setShowCreateProject] = useState(false);
    const [showProjectDropDown, setshowProjectDropDown] = useState(false);
    const [showConfigurationSetDropDown, setShowConfigurationSetDropDown] =
        useState(false);

    const location = useLocation();
    const navigate = useNavigate();

    if (!location.state?.productName) {
        navigate("/");
    }

    const productName = location.state?.productName;
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [configurationSets, setConfigurationSets] = useState([]);
    const [selectedConfigurationSet, setSelectedConfigurationSet] =
        useState(null);
    const [selectedConfiguration, setSelectedConfiguration] = useState(null);
    const [newProject, setNewProject] = useState({ name: "", description: "" });
    const [configSetName, setConfigSetName] = useState("");
    const [showCreateConfig, setShowCreateConfig] = useState(false);
    const [viewerPath, setViewerPath] = useState(
        `${process.env.REACT_APP_API_URL}/StdModel/output/1/result.svf`
    );

    const [modelConfig, setModelConfig] = useState({
        modelType: "ECP.1200",
        finishSurfaceLevel: 100,
        inletIL: 98,
        inletSize: "1",
        connectionType: "1",
        coverType: "Class E",
        freightLocationType: "QLD",
        riserHeight: 800,
    });

    const [isGenerating, setIsGenerating] = useState(false);
    const [totalPrice, setTotalPrice] = useState(1500);
    const [connectionId, setConnectionId] = useState(null);
    const [downloadUrls, setDownloadUrls] = useState({
        pdf: "",
        dwg: "",
        dwg3d: "",
    });

    const modelTypes = [
        "ECP.1200",
        "ECP.1500",
        "ECP.1850",
        "ECP.2200",
        "ECP.2500",
        "ECP.3000",
        "ECP.3500",
    ];

    const freightLocations = ["NT", "SA", "WA", "NSW", "QLD", "TAS", "VIC"];
    const coverTypes = ["Class B", "Class D", "Class E"];

    const ToggleShowCreateProjectOrDropdown = () => {
        setShowCreateProject(!showCreateProject);
        setshowProjectDropDown(!showProjectDropDown);
    };

    const getProductConfigsObject = async (configSetId) => {
        try {
            const response = await apiClient.get(
                `/productconfigs/object/${configSetId}`
            );
            console.log("44Response from getProductConfigsObject:", response);
            setModelConfig(response.data);
        } catch (error) {
            setSnackbar({
                open: true,
                message:
                    error.response?.data?.message ||
                    "Failed to fetch configuration",
                severity: "error",
            });
        }
    };

    const getConfigSetsByProject = (projectId, productType = null) => {
        if (!projectId) {
            return Promise.reject(new Error("Project ID is required"));
        }

        const params = new URLSearchParams();
        if (productType) params.append("productType", productType);

        return apiClient
            .get(
                `api/projectconfiguration/configset/project/${projectId}?${params}`
            )
            .then((response) => {
                setConfigurationSets(response.data);
                return response.data;
            })
            .catch((error) => {
                setSnackbar({
                    open: true,
                    message: "Failed to fetch configuration sets",
                    severity: "error",
                });
                setConfigurationSets([]);
                throw error;
            });
    };

    const handleSaveConfiguration = async (e) => {
        e.preventDefault();
        try {
            await saveConfiguration(
                selectedProject,
                productName,
                configSetName,
                modelConfig
            );
            setShowCreateConfig(false);
            await getConfigSetsByProject(selectedProject, productName);
            setConfigSetName("");
            setSnackbar({
                open: true,
                message: "Configuration saved successfully",
                severity: "success",
            });
        } catch (error) {
            setSnackbar({
                open: true,
                message: "Failed to save configuration",
                severity: "error",
            });
        }
    };

    // When user selects a configuration from dropdown
    const handleConfigurationSelect = async (e) => {
        const configSetId = e.target.value;
        setSelectedConfiguration(null);

        setTimeout(async () => {
            try {
                const response = await apiClient.get(
                    `api/projectConfiguration/productconfigs/object/${configSetId}`
                );
                const updatedConfig = {
                    ...modelConfig,
                    ...response.data,
                };
                setSelectedConfiguration(configSetId);
                setModelConfig(updatedConfig);
                setShowConfigurationSetDropDown(false);
                setSnackbar({
                    open: true,
                    message: "Configuration loaded successfully",
                    severity: "success",
                });
            } catch (error) {
                console.error("Error:", error);
                setSnackbar({
                    open: true,
                    message: "Failed to load configuration",
                    severity: "error",
                });
            }
        }, 0);
    };

    const getProductConfigs = async (configSetId) => {
        try {
            const response = await apiClient.get(
                `/productconfigs/${configSetId}`
            );
            setSelectedConfiguration(response.data);
        } catch (error) {
            setSnackbar({
                open: true,
                message: "Failed to fetch product configurations",
                severity: "error",
            });
        }
    };

    const saveConfiguration = async (
        projectId,
        productName,
        configName,
        modelConfig
    ) => {
        try {
            const configSetResponse = await apiClient.post(
                "/api/projectconfiguration/configset",
                {
                    projectId,
                    productType: productName,
                    configName,
                }
            );

            const configSetId = configSetResponse.data.id;
            const parameters = {};

            for (const [key, value] of Object.entries(modelConfig)) {
                parameters[key] = String(value || "");
            }

            await apiClient.post(
                `/api/projectconfiguration/bulk/${configSetId}`,
                parameters
            );

            setSnackbar({
                open: true,
                message: "Configuration saved successfully",
                severity: "success",
            });
        } catch (error) {
            setSnackbar({
                open: true,
                message:
                    error.response?.data?.message ||
                    "Failed to save configuration",
                severity: "error",
            });
            throw error;
        }
    };
    useEffect(() => {
        console.log("modelConfig changed:", modelConfig);
    }, [modelConfig]);

    useEffect(() => {
        const startConnection = async () => {
            const connection = new HubConnectionBuilder()
                .withUrl(
                    `${process.env.REACT_APP_API_URL}/api/signalr/designautomation`
                )
                .build();

            try {
                await connection.start();
                const id = await connection.invoke("getConnectionId");
                setConnectionId(id);

                connection.on("onComplete", () => {
                    setIsGenerating(false);
                });

                connection.on("onComponents", async (message) => {
                    const baseUrl = process.env.REACT_APP_API_URL;
                    const lastPartIndex = message.lastIndexOf("/output/");
                    const basePath = message.substring(0, lastPartIndex);

                    setViewerPath(`${baseUrl}/${message}`);
                    setDownloadUrls({
                        pdf: `${baseUrl}/${basePath}/1200 Compete.pdf`,
                        dwg: `${baseUrl}/${basePath}/1200 Compete.dwg`,
                        dwg3d: `${baseUrl}/${basePath}/1200 Compete._3D.dwg`,
                    });
                    setIsGenerating(false);
                });
            } catch (err) {
                setSnackbar({
                    open: true,
                    message: "Failed to connect to SignalR",
                    severity: "error",
                });
            }
        };

        startConnection();
    }, []);

    useEffect(() => {
        if (location.state?.projects?.length > 0) {
            setProjects(location.state.projects);
            setShowCreateProject(false);
            setshowProjectDropDown(true);
        } else {
            setShowCreateProject(true);
            setshowProjectDropDown(false);
        }

        if (location.state?.project && location.state?.productName) {
            setSelectedProject(location.state.project);
            getConfigSetsByProject(
                location.state.project,
                location.state.productName
            );
        }
    }, []);

    const handleCreateProject = async (e) => {
        e.preventDefault();
        try {
            const response = await apiClient.post(
                "/api/project/projectcreator",
                {
                    Name: newProject.name,
                    Description: newProject.description,
                    token: localStorage.getItem("jwtToken"),
                }
            );

            if (response.status === 200) {
                const { preparedProjectId } = response.data;
                if (preparedProjectId) {
                    await getConfigSetsByProject(
                        preparedProjectId,
                        productName
                    );
                    setSelectedProject(preparedProjectId);
                    setShowCreateProject(false);
                    setshowProjectDropDown(false);
                    setSnackbar({
                        open: true,
                        message: "Project created successfully",
                        severity: "success",
                    });
                }
            }
        } catch (err) {
            setSnackbar({
                open: true,
                message: "Failed to create project",
                severity: "error",
            });
        }
    };

    const loadInletSizes = async (modelType) => {
        try {
            return await apiClient.post(
                "/api/model/connectionsizes",
                modelType
            );
        } catch (err) {
            setSnackbar({
                open: true,
                message: "Failed to load inlet sizes",
                severity: "error",
            });
        }
    };

    const handleModelTypeChange = async (event) => {
        const value = event.target.value;
        setModelConfig((prev) => ({ ...prev, modelType: value }));
        await loadInletSizes(value);
    };

    const handleGenerate = async () => {
        setIsGenerating(true);
        const diameter = parseInt(modelConfig.modelType.split(".")[1]);
        const data = {
            browserConnectionId: connectionId,
            params: {
                Diameter: diameter.toString(),
                FSL: (modelConfig.finishSurfaceLevel * 100).toString(),
                InvertIL: (modelConfig.inletIL * 100).toString(),
                InletDiameter: "150",
                InletType: "RCP",
                LidThickness: "100",
            },
        };

        try {
            const response = await apiClient.post(
                "/api/aps/designautomation/workitems",
                data
            );
            if (response.status !== 200) {
                throw new Error("Failed to generate model");
            }
        } catch (err) {
            setIsGenerating(false);
            setSnackbar({
                open: true,
                message: "Failed to generate model",
                severity: "error",
            });
        }
    };

    const handleDownload = async (url, fileType) => {
        try {
            const workitemId = url.split("Workitems/")[1].split("/Output")[0];
            const response = await apiClient.get(
                `/api/aps/designautomation/download/${fileType}`,
                {
                    params: { workitemId },
                    responseType: "blob",
                    headers: {
                        "Content-Type": "application/octet-stream",
                        Authorization: `Bearer ${localStorage.getItem(
                            "jwtToken"
                        )}`,
                    },
                }
            );

            let filename = "1200 Compete";
            switch (fileType) {
                case "pdf":
                    filename += ".pdf";
                    break;
                case "dwg":
                    filename += ".dwg";
                    break;
                case "dwg3d":
                    filename += "._3D.dwg";
                    break;
            }

            const blob = new Blob([response.data], {
                type:
                    response.headers["content-type"] ||
                    "application/octet-stream",
            });
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl);

            try {
                const file = new File([blob], filename, {
                    type: response.headers["content-type"],
                });

                const formData = new FormData();
                formData.append("file", file);
                formData.append("projectId", selectedProject);

                const uploadResponse = await apiClient.post(
                    "/api/filestorage/upload",
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                if (uploadResponse.status === 200) {
                    setSnackbar({
                        open: true,
                        message:
                            "File downloaded and uploaded to project successfully",
                        severity: "success",
                    });
                }
            } catch (uploadErr) {
                setSnackbar({
                    open: true,
                    message: "File downloaded but failed to upload to project",
                    severity: "warning",
                });
            }
        } catch (err) {
            setSnackbar({
                open: true,
                message: `Error downloading file: ${err.message}`,
                severity: "error",
            });
        }
    };

    return (
        <Container>
            <Grid container spacing={2}>
                {/* Left Panel */}
                <Grid item xs={4}>
                    <Paper sx={{ p: 2 }}>
                        {/* Create Project Section */}
                        <Fade in={showCreateProject}>
                            <div>
                                {showCreateProject && (
                                    <CreateProjectForm
                                        newProject={newProject}
                                        setNewProject={setNewProject}
                                        onSubmit={handleCreateProject}
                                    />
                                )}
                            </div>
                        </Fade>

                        {/* Project Selection Section */}
                        <Fade in={showProjectDropDown && !selectedProject}>
                            <div>
                                {showProjectDropDown && !selectedProject && (
                                    <ProjectSelection
                                        projects={projects}
                                        selectedProject={selectedProject}
                                        onProjectSelect={(value) => {
                                            setSelectedProject(value);
                                            setshowProjectDropDown(false);
                                            getConfigSetsByProject(
                                                value,
                                                productName
                                            );
                                        }}
                                        onCreateNew={
                                            ToggleShowCreateProjectOrDropdown
                                        }
                                    />
                                )}
                            </div>
                        </Fade>

                        {/* Configuration Panel */}
                        {selectedProject && (
                            <Fade in={true}>
                                <div>
                                    <ModelConfigurationPanel
                                        modelConfig={modelConfig}
                                        modelTypes={modelTypes}
                                        coverTypes={coverTypes}
                                        freightLocations={freightLocations}
                                        onModelTypeChange={
                                            handleModelTypeChange
                                        }
                                        onConfigChange={(updates) =>
                                            setModelConfig((prev) => ({
                                                ...prev,
                                                ...updates,
                                            }))
                                        }
                                    />

                                    <PriceDisplay amount={totalPrice} />

                                    <ActionButtons
                                        isGenerating={isGenerating}
                                        onGenerate={handleGenerate}
                                        onSaveConfig={() =>
                                            setShowCreateConfig((prev) => !prev)
                                        }
                                        onShowConfigs={() =>
                                            setShowConfigurationSetDropDown(
                                                (prev) => !prev
                                            )
                                        }
                                        showCreateConfig={showCreateConfig}
                                        configSetName={configSetName}
                                        onNameChange={setConfigSetName}
                                        onSubmit={handleSaveConfiguration}
                                    />

                                    {/* Save Configuration Form */}
                                    {/* <Collapse in={showCreateConfig}>
                                        <SaveConfigForm
                                            configSetName={configSetName}
                                            onNameChange={setConfigSetName}
                                            onSubmit={handleSaveConfiguration}
                                        />
                                    </Collapse> */}

                                    {/* Configuration Selection */}
                                    <Collapse in={showConfigurationSetDropDown}>
                                        <ConfigurationSelector
                                            configurations={configurationSets}
                                            selectedConfig={
                                                selectedConfiguration
                                            }
                                            onChange={handleConfigurationSelect}
                                        />
                                    </Collapse>

                                    {/* Download Buttons */}
                                    <DownloadButtons
                                        urls={downloadUrls}
                                        onDownload={handleDownload}
                                    />
                                </div>
                            </Fade>
                        )}
                    </Paper>
                </Grid>

                {/* Right Panel - Viewer */}
                <Grid item xs={8}>
                    <Paper sx={{ p: 2, height: "calc(100vh - 32px)" }}>
                        <ForgeViewer url={viewerPath} loading={isGenerating} />
                    </Paper>
                </Grid>
            </Grid>

            {/* Notifications */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={3000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert
                    onClose={() => setSnackbar({ ...snackbar, open: false })}
                    severity={snackbar.severity}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};
// Sub-components for better organization
const CreateProjectForm = ({ newProject, setNewProject, onSubmit }) => (
    <Box component="form" onSubmit={onSubmit}>
        <Typography variant="h6">Create New Project</Typography>
        <TextField
            fullWidth
            margin="normal"
            label="Project Name"
            value={newProject.name || ""}
            onChange={(e) =>
                setNewProject((prev) => ({
                    ...prev,
                    name: e.target.value,
                }))
            }
        />
        <TextField
            fullWidth
            margin="normal"
            label="Description"
            multiline
            rows={3}
            value={newProject.description || ""}
            onChange={(e) =>
                setNewProject((prev) => ({
                    ...prev,
                    description: e.target.value,
                }))
            }
        />
        <Button type="submit" variant="contained" fullWidth sx={{ mt: 2 }}>
            Create Project
        </Button>
    </Box>
);

const ProjectSelection = ({
    projects,
    selectedProject,
    onProjectSelect,
    onCreateNew,
}) => (
    <Box>
        <Typography variant="h6">Choose from an existing project</Typography>
        <FormControl fullWidth margin="normal">
            <InputLabel>Project</InputLabel>
            <Select
                value={selectedProject || ""}
                onChange={(e) => onProjectSelect(e.target.value)}
            >
                {projects.map((project) => (
                    <MenuItem key={project.id} value={project.id}>
                        {project.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
        <Typography variant="h6">Or Create a New Project</Typography>
        <Button
            variant="contained"
            sx={{ mt: 2, textAlign: "center" }}
            color="primary"
            onClick={onCreateNew}
        >
            Create a New Project
        </Button>
    </Box>
);

const ModelConfigurationPanel = ({
    modelConfig,
    modelTypes,
    coverTypes,
    freightLocations,
    onModelTypeChange,
    onConfigChange,
}) => (
    <Box>
        <FormControl fullWidth margin="normal">
            <InputLabel>Model Type</InputLabel>
            <Select value={modelConfig.modelType} onChange={onModelTypeChange}>
                {modelTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                        {type}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>

        <TextField
            fullWidth
            margin="normal"
            type="number"
            label="Finish Surface Level (m)"
            value={modelConfig.finishSurfaceLevel}
            onChange={(e) =>
                onConfigChange({
                    finishSurfaceLevel: parseFloat(e.target.value),
                })
            }
        />

        <TextField
            fullWidth
            margin="normal"
            type="number"
            label="Inlet IL (m)"
            value={modelConfig.inletIL}
            onChange={(e) =>
                onConfigChange({
                    inletIL: parseFloat(e.target.value),
                })
            }
        />

        <FormControl fullWidth margin="normal">
            <InputLabel>Cover Type</InputLabel>
            <Select
                value={modelConfig.coverType}
                onChange={(e) => onConfigChange({ coverType: e.target.value })}
            >
                {coverTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                        {type}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
            <InputLabel>Freight Location</InputLabel>
            <Select
                value={modelConfig.freightLocationType}
                onChange={(e) =>
                    onConfigChange({
                        freightLocationType: e.target.value,
                    })
                }
            >
                {freightLocations.map((location) => (
                    <MenuItem key={location} value={location}>
                        {location}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    </Box>
);

const PriceDisplay = ({ amount }) => (
    <Typography variant="h6" sx={{ mt: 2 }}>
        Price: ${amount}
    </Typography>
);

const ActionButtons = ({
    isGenerating,
    onGenerate,
    onSaveConfig,
    onShowConfigs,
    showCreateConfig,
    configSetName,
    onNameChange,
    onSubmit,
}) => (
    <Box>
        <Button
            fullWidth
            variant="contained"
            onClick={onGenerate}
            disabled={isGenerating}
            sx={{ mt: 2 }}
        >
            {isGenerating ? <CircularProgress size={24} /> : "Generate"}
        </Button>

        <Box>
            <Button
                fullWidth
                variant="contained"
                onClick={onSaveConfig}
                disabled={isGenerating}
                sx={{ mt: 2 }}
            >
                Save Configuration
            </Button>

            <Collapse in={showCreateConfig}>
                <Box component="form" onSubmit={onSubmit}>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Configuration Name"
                        value={configSetName || ""}
                        onChange={(e) => onNameChange(e.target.value)}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        fullWidth
                        sx={{ mt: 2 }}
                    >
                        Save
                    </Button>
                </Box>
            </Collapse>
        </Box>

        <Button
            fullWidth
            variant="contained"
            onClick={onShowConfigs}
            disabled={isGenerating}
            sx={{ mt: 2 }}
        >
            My Configurations
        </Button>
    </Box>
);

const ConfigurationSelector = ({
    configurations,
    selectedConfig,
    onChange,
}) => (
    <Box>
        <FormControl fullWidth margin="normal">
            <InputLabel>Configuration</InputLabel>
            <Select value={selectedConfig || ""} onChange={onChange}>
                {configurations.map((configset) => (
                    <MenuItem key={configset.id} value={configset.id}>
                        {configset.configName}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    </Box>
);

const DownloadButtons = ({ urls, onDownload }) => (
    <Box>
        {Object.entries(urls).map(([type, url]) => (
            <Button
                key={type}
                fullWidth
                variant="outlined"
                onClick={() => onDownload(url, type)}
                disabled={!url}
                sx={{ mt: 1 }}
            >
                Download {type.toUpperCase()}
            </Button>
        ))}
    </Box>
);

export default DesignAutomation;
