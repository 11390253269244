// routes/AppRoutes.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "../Pages/homePage";
import AboutPage from "../Pages/aboutPage";
import PlayPage from "../Pages/playPage";
import PlayPage2 from "../Pages/playPage2";

import SignupPage from "../Pages/signupPage";
import DashBoardPage from "../Pages/dashBoardPage";
import EmailConfirmationPage from "../Pages/emailConfirmationPage";
import XtrCalculatorPage from "../Pages/xtrCalculatorPage";
import CaseStudiesPage from "../Pages/caseStudiesPage/caseStudiesPage";
import { AuthProvider } from "../Helpers/auth-context";
import ProtectedRoute from "./ProtectedRoute";
import EmailVerificationPage from "../Pages/emailVerificationPage";
import PleaseCheckYourEmailPage from "../Pages/pleaseCheckYourEmailPage";
import ProductHomePage from "../Pages/productHomePage/productHomePage";
import ForgotPasswordPage from "../Pages/forgotPasswordPage";
import ResetPasswordPage from "../Pages/ResetPasswordPage";
import LoginPage from "../loginPage";
import FileStoragePage from "../Pages/fileStoragePage";
import ProjectsPage from "../Pages/projectsPage";
import GenerateTemporaryLinkPage from "../Pages/warrantyQuotePages/generateTemporaryLinkPage";
import ClientFormPage from "../Pages/warrantyQuotePages/clientFormPage";
import AdminProjectListPage from "../Pages/warrantyQuotePages/adminProjectListPage";
import EditProjectPage from "../Pages/warrantyQuotePages/editProjectPage";
import WarrentyManagementPage from "../Pages/warrantyQuotePages/warrantyManagementPage";
import WarrentyDetailPage from "../Pages/warrantyQuotePages/warrentyDetailPage";
import { AutoLogoutProvider } from "../Helpers/AutoLogoutProvider";
import DesignToolPage from "../Pages/designToolPage/designToolPage";
import PumpStationForm from "../Pages/testPage";

function AppRoutes() {
    return (
        <AuthProvider>
            <AutoLogoutProvider timeout={15 * 60 * 1000}>
                <Routes>
                    <Route path="/tool" element={<DesignToolPage />} />
                    <Route path="/test" element={<PumpStationForm />} />

                    <Route path="/about" element={<AboutPage />} />
                    <Route path="/play" element={<PlayPage />} />
                    <Route path="/play2" element={<PlayPage2 />} />

                    <Route path="/signup" element={<SignupPage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route
                        path="/dashboard"
                        element={
                            <ProtectedRoute>
                                <DashBoardPage />
                            </ProtectedRoute>
                        }
                    />
                    <Route path="/case-studies" element={<CaseStudiesPage />} />
                    <Route
                        path="/forgot-password"
                        element={<ForgotPasswordPage />}
                    />
                    <Route
                        path="/reset-password"
                        element={<ResetPasswordPage />}
                    />
                    <Route path="/projects" element={<ProjectsPage />} />
                    <Route
                        path="/projects/:projectId/files"
                        element={<FileStoragePage />}
                    />

                    {/* Newly added routes */}
                    <Route
                        path="/generate-link"
                        element={<GenerateTemporaryLinkPage />}
                    />
                    <Route path="/form/:token" element={<ClientFormPage />} />
                    <Route
                        path="/admin/projects"
                        element={<AdminProjectListPage />}
                    />
                    <Route
                        path="/warrenty/edit/:id"
                        element={<EditProjectPage />}
                    />
                    <Route
                        path="/warrenty"
                        element={<WarrentyManagementPage />}
                    />
                    <Route
                        path="/warrenty/detail/:id"
                        element={<WarrentyDetailPage />}
                    />

                    {/* Newly added routes */}

                    <Route
                        path="/verify-email"
                        element={<EmailVerificationPage />}
                    />
                    <Route
                        path="/check-your-email"
                        element={<PleaseCheckYourEmailPage />}
                    />
                    <Route path="/xtr" element={<HomePage />} />
                    <Route
                        path="/confirmation-success"
                        element={<EmailConfirmationPage />}
                    />
                    <Route path="/" element={<ProductHomePage />} />
                    <Route path="*" element={<h1>404 Not Found</h1>} />
                </Routes>
            </AutoLogoutProvider>
        </AuthProvider>
    );
}

export default AppRoutes;
