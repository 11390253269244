"use client";

import { useState } from "react";
import {
    AppBar,
    Toolbar,
    Button,
    Container,
    Card,
    CardContent,
    Typography,
    TextField,
    Select,
    MenuItem,
    FormControl,
    Grid,
    Checkbox,
    FormControlLabel,
    Box,
    Divider,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

function PumpStationForm() {
    const [showOpenings, setShowOpenings] = useState(false);
    const [volume, setVolume] = useState("");
    const [invert, setInvert] = useState("");

    return (
        <>
            <AppBar
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                position="static"
                color="transparent"
                elevation={0}
            >
                <Toolbar>
                    <Button
                        sx={{
                            marginRight: 3,
                            color: "white",
                            backgroundColor: "#54c9ec",
                        }}
                    >
                        ADD NEW
                    </Button>
                    <Button
                        sx={{
                            marginRight: 3,
                            color: "white",
                            backgroundColor: "#54c9ec",
                        }}
                    >
                        VIEW QUOTES
                    </Button>
                    <Button
                        sx={{
                            marginRight: 3,
                            color: "white",
                            backgroundColor: "#54c9ec",
                        }}
                    >
                        CHANGE PASSWORD
                    </Button>
                    <Button
                        sx={{
                            marginRight: 3,
                            color: "white",
                            backgroundColor: "#54c9ec",
                        }}
                    >
                        USER LIST
                    </Button>
                    <Button
                        sx={{
                            marginRight: 3,
                            color: "white",
                            backgroundColor: "#54c9ec",
                        }}
                    >
                        SALES REP MENU
                    </Button>
                    <Button
                        sx={{
                            marginRight: 3,
                            color: "white",
                            backgroundColor: "#54c9ec",
                        }}
                    >
                        LOG OUT
                    </Button>
                </Toolbar>
            </AppBar>

            <Container style={{ padding: "20px" }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={9}>
                        <Typography variant="h4" align="center" gutterBottom>
                            Fibreglass Pump Station
                        </Typography>

                        {/* Station Configuration */}
                        <Card style={{ marginBottom: "30px" }}>
                            <CardContent style={{ padding: "20px" }}>
                                <Typography variant="h6" gutterBottom>
                                    Station Configuration
                                </Typography>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    marginBottom="20px"
                                >
                                    <Typography
                                        variant="body2"
                                        style={{
                                            marginRight: "10px",
                                            minWidth: "150px",
                                        }}
                                    >
                                        Enter Diameter
                                    </Typography>
                                    <FormControl fullWidth>
                                        <Select defaultValue="not-required">
                                            <MenuItem value="not-required">
                                                Not Required
                                            </MenuItem>
                                            <MenuItem value="1000">
                                                1000mm
                                            </MenuItem>
                                            <MenuItem value="1200">
                                                1200mm
                                            </MenuItem>
                                            <MenuItem value="1500">
                                                1500mm
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    marginBottom="20px"
                                >
                                    <Typography
                                        variant="body2"
                                        style={{
                                            marginRight: "10px",
                                            minWidth: "150px",
                                        }}
                                    >
                                        Enter Depth
                                    </Typography>
                                    <FormControl fullWidth>
                                        <Select defaultValue="not-required">
                                            <MenuItem value="not-required">
                                                Not Required
                                            </MenuItem>
                                            <MenuItem value="2">2m</MenuItem>
                                            <MenuItem value="3">3m</MenuItem>
                                            <MenuItem value="4">4m</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>

                                <Divider style={{ margin: "20px 0" }}>
                                    <Typography color="textSecondary">
                                        — or —
                                    </Typography>
                                </Divider>

                                <TextField
                                    fullWidth
                                    label="Volume Required (Liters under invert)"
                                    value={volume}
                                    onChange={(e) => setVolume(e.target.value)}
                                    style={{ marginBottom: "20px" }}
                                />
                                <TextField
                                    fullWidth
                                    label="Invert From SL (Meters from surface level)"
                                    value={invert}
                                    onChange={(e) => setInvert(e.target.value)}
                                    style={{ marginBottom: "20px" }}
                                />
                                <Button
                                    variant="contained"
                                    fullWidth
                                    style={{
                                        marginTop: "10px",
                                        marginBottom: "20px",
                                    }}
                                >
                                    Calculate Volume
                                </Button>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    marginBottom="20px"
                                >
                                    <Typography
                                        variant="body2"
                                        style={{
                                            marginRight: "10px",
                                            minWidth: "150px",
                                        }}
                                    >
                                        Opening Size{" "}
                                    </Typography>
                                    <FormControl fullWidth>
                                        <Select defaultValue="not-required">
                                            <MenuItem value="not-required">
                                                Not Required
                                            </MenuItem>
                                            <MenuItem value="100mm">
                                                100 mm
                                            </MenuItem>
                                            <MenuItem value="200mm">
                                                200 mm{" "}
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    marginBottom="20px"
                                >
                                    <Typography
                                        variant="body2"
                                        style={{
                                            marginRight: "10px",
                                            minWidth: "150px",
                                        }}
                                    >
                                        Pitt Access Cover
                                    </Typography>
                                    <FormControl fullWidth>
                                        <Select defaultValue="not-required">
                                            <MenuItem value="not-required">
                                                Not Required
                                            </MenuItem>
                                            <MenuItem value="standard">
                                                Standard Cover
                                            </MenuItem>
                                            <MenuItem value="heavy">
                                                Heavy Duty Cover
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Button style={{ marginTop: "10px" }}>
                                    View/Hide Back Openings
                                </Button>
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    onClick={() =>
                                        setShowOpenings(!showOpenings)
                                    }
                                    endIcon={
                                        showOpenings ? (
                                            <KeyboardArrowUpIcon />
                                        ) : (
                                            <KeyboardArrowDownIcon />
                                        )
                                    }
                                    style={{ marginTop: "10px" }}
                                >
                                    {showOpenings ? "Hide" : "View"} Entire
                                    Openings
                                </Button>
                            </CardContent>
                        </Card>

                        {/* Pipework Configuration */}
                        <Card style={{ marginBottom: "30px" }}>
                            <CardContent style={{ padding: "20px" }}>
                                <Typography variant="h6" gutterBottom>
                                    Pipework and Guide Rail Configuration
                                </Typography>
                                {[
                                    "Pipework Setup",
                                    "Pipework Valve Types",
                                    "Pipework Size",
                                    "Pipework Pipe Type",
                                    "Guiderails",
                                ].map((label, index) => (
                                    <Box
                                        key={index}
                                        display="flex"
                                        alignItems="center"
                                        marginBottom="20px"
                                    >
                                        <Typography
                                            variant="body2"
                                            style={{
                                                marginRight: "10px",
                                                minWidth: "150px",
                                            }}
                                        >
                                            {label}
                                        </Typography>
                                        <FormControl fullWidth>
                                            <Select defaultValue="not-required">
                                                <MenuItem value="not-required">
                                                    Not Required
                                                </MenuItem>
                                                <MenuItem value="option1">
                                                    Option 1
                                                </MenuItem>
                                                <MenuItem value="option2">
                                                    Option 2
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                ))}
                            </CardContent>
                        </Card>

                        {/* Options */}
                        <Card style={{ marginBottom: "30px" }}>
                            <CardContent style={{ padding: "20px" }}>
                                <Typography variant="h6" gutterBottom>
                                    Options
                                </Typography>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    marginBottom="20px"
                                >
                                    <Typography
                                        variant="body2"
                                        style={{
                                            marginRight: "10px",
                                            minWidth: "150px",
                                        }}
                                    >
                                        Ladder
                                    </Typography>
                                    <FormControl fullWidth>
                                        <Select defaultValue="not-required">
                                            <MenuItem value="not-required">
                                                Not Required
                                            </MenuItem>
                                            <MenuItem value="aluminum">
                                                Aluminum
                                            </MenuItem>
                                            <MenuItem value="steel">
                                                Steel
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    marginBottom="20px"
                                >
                                    <Typography
                                        variant="body2"
                                        style={{
                                            marginRight: "10px",
                                            minWidth: "150px",
                                        }}
                                    >
                                        Lifting Chain
                                    </Typography>
                                    <FormControl fullWidth>
                                        <Select defaultValue="not-required">
                                            <MenuItem value="not-required">
                                                Not Required
                                            </MenuItem>
                                            <MenuItem value="standard">
                                                Standard Chain
                                            </MenuItem>
                                            <MenuItem value="heavy">
                                                Heavy Duty Chain
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </CardContent>
                        </Card>

                        {/* Valve Chamber Configuration */}
                        <Card style={{ marginBottom: "30px" }}>
                            <CardContent style={{ padding: "20px" }}>
                                <Typography variant="h6" gutterBottom>
                                    Valve Chamber Configuration
                                </Typography>
                                {[
                                    "VC Size",
                                    "VC Installation",
                                    "VC Opening Size",
                                    "VC Access Cover",
                                ].map((label, index) => (
                                    <Box
                                        key={index}
                                        display="flex"
                                        alignItems="center"
                                        marginBottom="20px"
                                    >
                                        <Typography
                                            variant="body2"
                                            style={{
                                                marginRight: "10px",
                                                minWidth: "150px",
                                            }}
                                        >
                                            {label}
                                        </Typography>
                                        <FormControl fullWidth>
                                            <Select defaultValue="not-required">
                                                <MenuItem value="not-required">
                                                    Not Required
                                                </MenuItem>
                                                <MenuItem value="option1">
                                                    Option 1
                                                </MenuItem>
                                                <MenuItem value="option2">
                                                    Option 2
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                ))}
                            </CardContent>
                        </Card>

                        {/* Inlets */}
                        <Card style={{ marginBottom: "30px" }}>
                            <CardContent style={{ padding: "20px" }}>
                                <Typography variant="h6" gutterBottom>
                                    Inlets
                                </Typography>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    marginBottom="20px"
                                >
                                    <Typography
                                        variant="body2"
                                        style={{
                                            marginRight: "10px",
                                            minWidth: "150px",
                                        }}
                                    >
                                        Inlet Size
                                    </Typography>
                                    <FormControl fullWidth>
                                        <Select defaultValue="not-required">
                                            <MenuItem value="not-required">
                                                Not Required
                                            </MenuItem>
                                            <MenuItem value="100">
                                                100mm
                                            </MenuItem>
                                            <MenuItem value="150">
                                                150mm
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    marginBottom="20px"
                                >
                                    <Typography
                                        variant="body2"
                                        style={{
                                            marginRight: "10px",
                                            minWidth: "150px",
                                        }}
                                    >
                                        Inlet Type
                                    </Typography>
                                    <FormControl fullWidth>
                                        <Select defaultValue="not-required">
                                            <MenuItem value="not-required">
                                                Not Required
                                            </MenuItem>
                                            <MenuItem value="gravity">
                                                Gravity
                                            </MenuItem>
                                            <MenuItem value="pressure">
                                                Pressure
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    marginBottom="20px"
                                >
                                    <Typography
                                        variant="body2"
                                        style={{
                                            marginRight: "10px",
                                            minWidth: "150px",
                                        }}
                                    >
                                        Inlet Valve
                                    </Typography>
                                    <FormControl fullWidth>
                                        <Select defaultValue="not-required">
                                            <MenuItem value="not-required">
                                                Not Required
                                            </MenuItem>
                                            <MenuItem value="gate">
                                                Gate Valve
                                            </MenuItem>
                                            <MenuItem value="check">
                                                Check Valve
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </CardContent>
                        </Card>

                        {/* Freight Estimate */}
                        <Card style={{ marginBottom: "30px" }}>
                            <CardContent style={{ padding: "20px" }}>
                                <Typography variant="h6" gutterBottom>
                                    Freight Estimate
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    paragraph
                                >
                                    If you would like a freight estimate
                                    included in your quote, please enter your
                                    location below.
                                </Typography>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    marginBottom="20px"
                                >
                                    <Typography
                                        variant="body2"
                                        style={{
                                            marginRight: "10px",
                                            minWidth: "150px",
                                        }}
                                    >
                                        Suburb
                                    </Typography>
                                    <TextField fullWidth />
                                </Box>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    marginBottom="20px"
                                >
                                    <Typography
                                        variant="body2"
                                        style={{
                                            marginRight: "10px",
                                            minWidth: "150px",
                                        }}
                                    >
                                        Post Code
                                    </Typography>
                                    <TextField fullWidth />
                                </Box>
                            </CardContent>
                        </Card>

                        {/* Lead Time */}
                        <Card style={{ marginBottom: "30px" }}>
                            <CardContent style={{ padding: "20px" }}>
                                <Typography variant="h6" gutterBottom>
                                    Lead Time
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    4-5 Weeks from working Drawing Approval and
                                    Receipt of Pump Station, Depending on work
                                    Lead at Time of Order.
                                </Typography>
                            </CardContent>
                        </Card>

                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            marginTop={2}
                        >
                            <Button
                                variant="outlined"
                                style={{ marginRight: "10px" }}
                            >
                                Reset
                            </Button>
                            <Button variant="contained">Submit</Button>
                        </Box>
                    </Grid>

                    {/* Sidebar */}
                    <Grid item xs={12} md={3}>
                        <Card>
                            <CardContent style={{ padding: "20px" }}>
                                <Typography variant="h6" gutterBottom>
                                    Links
                                </Typography>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Product Brochure"
                                />
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Install Brochure"
                                />
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="General Drawing"
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default PumpStationForm;
