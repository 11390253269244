import React, { useEffect, useRef } from "react";
import { CircularProgress } from "@mui/material";

/* global Autodesk */

const ForgeViewer = ({
    url = `${process.env.REACT_APP_API_URL}/StdModel/output/1/result.svf`,
    loading,
}) => {
    const viewerRef = useRef(null);
    const containerRef = useRef(null);

    const initViewer = async (documentUrl) => {
        const options = {
            env: "Local",
            document: documentUrl,
        };

        if (viewerRef.current) {
            // If viewer exists, just load new document
            try {
                await viewerRef.current.tearDown();
                await viewerRef.current.start(options.document, options);
            } catch (err) {
                console.error("Error reinitializing viewer:", err);
            }
        } else {
            // Initialize new viewer
            try {
                await new Promise((resolve, reject) => {
                    Autodesk.Viewing.Initializer(options, () => {
                        try {
                            viewerRef.current =
                                new Autodesk.Viewing.GuiViewer3D(
                                    containerRef.current,
                                    { extensions: [] }
                                );
                            viewerRef.current.start(options.document, options);
                            resolve();
                        } catch (err) {
                            reject(err);
                        }
                    });
                });
            } catch (err) {
                console.error("Error initializing viewer:", err);
            }
        }
    };

    const loadViewer = async (documentUrl) => {
        // Check if Autodesk scripts are already loaded
        if (window.Autodesk) {
            await initViewer(documentUrl);
            return;
        }

        // Load Autodesk scripts
        try {
            await new Promise((resolve, reject) => {
                const script = document.createElement("script");
                script.src =
                    "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/viewer3D.min.js";
                script.onload = () => {
                    const style = document.createElement("link");
                    style.rel = "stylesheet";
                    style.href =
                        "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/style.min.css";
                    style.onload = () => resolve();
                    style.onerror = () =>
                        reject(new Error("Failed to load viewer stylesheet"));
                    document.head.appendChild(style);
                };
                script.onerror = () =>
                    reject(new Error("Failed to load viewer script"));
                document.head.appendChild(script);
            });

            await initViewer(documentUrl);
        } catch (err) {
            console.error("Error loading viewer scripts:", err);
        }
    };

    useEffect(() => {
        if (!loading) {
            loadViewer(url);
        }
        return () => {
            if (viewerRef.current) {
                viewerRef.current.tearDown();
                viewerRef.current = null;
            }
        };
    }, [url, loading]);

    return (
        <div
            id="forgeViewer"
            ref={containerRef}
            style={{
                width: "100%",
                height: "100%",
                minHeight: "400px",
                position: "relative",
            }}
        />
    );
};

export default ForgeViewer;
