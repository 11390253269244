import React, { act, useState } from "react";
import { Dialog, Box, Typography, Grow, Menu, MenuItem } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import BuildIcon from "@mui/icons-material/Build";
import FolderIcon from "@mui/icons-material/Folder";
import EcoprotectorOverviewUI from "../../Components/ProductOverviews/EcoprotectorOverviewUI/EcoprotectorOverviewUI";
import EcoprotectorSpecificationsUI from "../../Components/ProductOverviews/EcoprotectorSpecificationsUI/EcoprotectorSpecificaitonsUI";
import EcoprotectorDrawingDesignToolUI from "../../Components/ProductOverviews/EcoprotectorDrawingDesignToolUI/EcoprotectorDrawingDesignToolUI";

const getProductNavButtons = (productName) => {
    // Default "To be announced" items
    const tbaSpec = [
        {
            title: "To be announced",
            url: "#",
        },
        {
            title: "To be announced",
            url: "#",
        },
        {
            title: "To be announced",
            url: "#",
        },
    ];

    const tbaInstall = [
        {
            title: "To be announced",
            url: "#",
        },
        {
            title: "To be announced",
            url: "#",
        },
        {
            title: "To be announced",
            url: "#",
        },
    ];

    // Product-specific content
    const productContent = {
        ECOPROTECTOR: {
            specifications: [
                {
                    title: "EcoProtector Specification Overview",
                    url: "#",
                },
                {
                    title: "EcoProtector Brochure (Direct Download)",
                    url: "https://jweoigjwiojjr.s3.ap-southeast-2.amazonaws.com/EcoProtector+Brochure+02.pdf",
                },
                {
                    title: "Ecoprotector Technical Specification (Direct Download)",
                    url: "https://jweoigjwiojjr.s3.ap-southeast-2.amazonaws.com/Ecoprotector+Technical+Specification+01.pdf",
                },
                {
                    title: "MUSIC Files (Direct Download)",
                    url: "https://jweoigjwiojjr.s3.ap-southeast-2.amazonaws.com/MUSIC+Files.zip",
                },
            ],
            installation: [
                {
                    title: "Installation Manual",
                    url: "https://jweoigjwiojjr.s3.ap-southeast-2.amazonaws.com/EcoProtector+Maintenance.pdf",
                },
                {
                    title: "Maintenance Manual",
                    url: "https://jweoigjwiojjr.s3.ap-southeast-2.amazonaws.com/EcoProtector+GPT+-+Maintenance+Register.pdf",
                },
                {
                    title: "Maintenance Register",
                    url: "https://jweoigjwiojjr.s3.ap-southeast-2.amazonaws.com/EcoProtector+GPT+-+Maintenance+Register.pdf",
                },
            ],
            designToolUrl: "https://specifyingpdf.protector.com.au/",
        },
        // Add other products here with their specific content
    };

    // Get content based on product name or use TBA content
    const currentProduct = productContent[productName] || {};

    return [
        {
            icon: <InfoIcon />,
            title: "Product Overview",
            id: "overview",
        },
        {
            icon: <SettingsApplicationsIcon />,
            title: "Product Specifications",
            id: "specifications",
            hasDropdown: true,
            dropdownItems: currentProduct.specifications || tbaSpec,
        },
        {
            icon: <ArchitectureIcon />,
            title: "Drawing Design Tool",
            id: "design",
            url: currentProduct.designToolUrl || "#",
        },
        {
            icon: <BuildIcon />,
            title: "Installation Data",
            id: "installation",
            hasDropdown: true,
            dropdownItems: currentProduct.installation || tbaInstall,
        },
        {
            icon: <FolderIcon />,
            title: "Case Studies",
            id: "cases",
        },
    ];
};

const ProductDialog = ({ open, onClose, product, anchorEl }) => {
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [activeButton, setActiveButton] = useState("overview");
    const [selectedProductTab, setSelectedProductTab] = useState("overview");

    const openWithPostToken = () => {
        const jwtToken = localStorage.getItem("jwtToken");

        if (jwtToken) {
            const form = document.createElement("form");
            form.method = "POST";
            //  form.action = "http://localhost:5186/";
            form.action = process.env.REACT_APP_API_URL;

            // Create a hidden input with the token
            const hiddenField = document.createElement("input");
            hiddenField.type = "hidden";
            hiddenField.name = "token";
            hiddenField.value = jwtToken;

            form.appendChild(hiddenField);
            document.body.appendChild(form);
            form.target = "_blank"; // Open in a new tab
            form.submit();

            document.body.removeChild(form); // Clean up the DOM
        } else {
            console.error("No token found in local storage.");
        }
    };

    const handleButtonClick = (event, button) => {
        event.stopPropagation();
        // if (button.id === "design") {
        //     if (button.url !== "#") {
        //         openWithPostToken();
        //     }
        // }
        setSelectedProductTab(button.id);
        setActiveButton(button.id);
        if (button.hasDropdown) {
            setMenuAnchor(event.currentTarget);
        }
    };

    const handleMenuClose = () => {
        setMenuAnchor(null);
        //   setActiveButton(null);
    };

    const handleMenuItemClick = (url) => {
        if (url !== "#") {
            window.open(url, "_blank");
        }
        handleMenuClose();
    };

    const navButtons = getProductNavButtons(product?.name);
    const activeDropdownItems =
        navButtons.find((btn) => btn.id === activeButton)?.dropdownItems || [];

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth={false}
            TransitionComponent={Grow}
            TransitionProps={{
                style: {
                    transformOrigin: anchorEl
                        ? `${anchorEl.offsetLeft}px ${
                              anchorEl.offsetTop + 100
                          }px`
                        : "0 0",
                },
            }}
            PaperProps={{
                sx: {
                    minWidth: "95vw",
                    maxHeight: "100vh",
                    bgcolor: "background.paper",
                    borderRadius: 1,
                    overflow: "auto",
                },
            }}
            sx={{
                "& .MuiDialog-container": {
                    alignItems: "flex-start",
                    // pt: 10,
                    maxWidth: "100vw",
                    maxHeight: "100vh",
                },
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    width: "100%",
                    borderBottom: "1px solid rgba(0,0,0,0.12)",
                }}
            >
                {navButtons.map((button) => (
                    <Box
                        key={button.id}
                        onClick={(e) => handleButtonClick(e, button)}
                        sx={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: 1,
                            py: 2,
                            px: 3,
                            cursor: "pointer",
                            bgcolor:
                                button.id === activeButton
                                    ? "#185576"
                                    : "#696a6a",
                            color: "white",
                            transition: "all 0.2s ease",
                            "&:hover": {
                                bgcolor: "#185576",
                            },
                            borderRight: "3px solid rgba(0,0,0,0.12)",
                        }}
                    >
                        {button.icon}
                        <Typography
                            variant="button"
                            sx={{ whiteSpace: "nowrap" }}
                        >
                            {button.title}
                        </Typography>
                    </Box>
                ))}

                <Menu
                    anchorEl={menuAnchor}
                    open={Boolean(menuAnchor)}
                    onClose={handleMenuClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    PaperProps={{
                        sx: {
                            minWidth: "15%",
                            bgcolor: "#185576",
                            color: "white",
                            mt: 0,
                            borderRadius: 0,
                            "& .MuiMenuItem-root": {
                                py: 2,
                                px: 3,
                                "&:hover": {
                                    bgcolor: "rgba(255, 255, 255, 0.1)",
                                },
                            },
                        },
                    }}
                    sx={{
                        "& .MuiMenu-paper": {
                            marginTop: 0,
                        },
                    }}
                >
                    {activeDropdownItems.map((item) => (
                        <MenuItem
                            key={item.title}
                            onClick={() => handleMenuItemClick(item.url)}
                            sx={{
                                width: "100%",
                                fontSize: "1rem",
                                cursor:
                                    item.url === "#" ? "default" : "pointer",
                                opacity: item.url === "#" ? 0.7 : 1,
                            }}
                        >
                            {item.title}
                        </MenuItem>
                    ))}
                </Menu>
            </Box>

            {product?.name === "ECOPROTECTOR" ? (
                selectedProductTab === "design" ? (
                    <EcoprotectorDrawingDesignToolUI
                        productName={product.name}
                    />
                ) : selectedProductTab === "specifications" ? (
                    <EcoprotectorSpecificationsUI />
                ) : selectedProductTab === "overview" ? (
                    <EcoprotectorOverviewUI />
                ) : selectedProductTab ===
                  "installation" ? null : selectedProductTab ===
                  "cases" ? null : null
            ) : (
                <Box
                    sx={{
                        width: "100%",
                        height: "100%",
                        overflow: "auto", // Change to hidden to prevent scrolling
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Box
                        sx={{
                            width: "100%", // Change maxwidth to width
                            maxWidth: "800px", // Fix typo (maxwidth -> maxWidth)
                            height: "70vh", // Change maxHeight to height
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Box
                            component="img"
                            src={product?.image}
                            alt={product?.name}
                            sx={{
                                maxWidth: "100%", // Change width to maxWidth
                                maxHeight: "100%", // Change height to maxHeight
                                objectFit: "contain", // Change to contain to prevent cropping
                                objectPosition: "center",
                            }}
                        />
                    </Box>
                </Box>
            )}
        </Dialog>
    );
};

export default ProductDialog;
