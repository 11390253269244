import React, { useState, useEffect } from "react";
import {
    Box,
    Container,
    Typography,
    TextField,
    Button,
    Grid,
    Card,
    CardContent,
    FormControlLabel,
    Checkbox,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
} from "@mui/material";
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { Alert, AlertTitle } from "@mui/material";
import { UploadFile, Close as CloseIcon } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import apiClient from "../../Helpers/apiClient";
import Snackbar from "@mui/material/Snackbar";

import ChecklistDialog from "../clientWarrantyFormPage/ChecklistDialog";
const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

export default function ClientFormPage() {
    const { token } = useParams();
    const [isValidToken, setIsValidToken] = useState(false);

    const [installationDate, setInstallationDate] = useState(null);
    // const [photos, setPhotos] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [checklistComplete, setChecklistComplete] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [error, setError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [serverError, setServerError] = useState("");
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "success",
    });
    const handleSnackbarClose = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    useEffect(() => {
        const validateToken = async () => {
            if (!token) {
                setError("No token provided.");
                setIsValidToken(false);
                return;
            }

            try {
                const response = await apiClient.get(
                    `/api/projectuser/form/validate/${token}`
                );
                setIsValidToken(true);
            } catch (err) {
                setError(
                    err.response?.data?.message || "Invalid or expired link."
                );
                setIsValidToken(false);
            }
        };

        validateToken();
    }, [token]);

    const [formData, setFormData] = useState({
        projectName: "",
        productName: "",
        projectReference: "",
        installerName: "",
        installationLocation: "",
        siteOwner: "",
        contactNumber: "",
        email: "",
        mailingAddress: "",
        consent: false,
    });

    const handleChange = (e) => {
        const { name, value, checked } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: name === "consent" ? checked : value,
        }));
    };

    // const handlePhotoUpload = (event) => {
    //     if (event.target.files) {
    //         setPhotos(Array.from(event.target.files));
    //     }
    // };
    const [files, setFiles] = useState([]);

    // Helper function to ensure unique file names
    const generateUniqueFileName = (name) => {
        let uniqueName = name;
        let counter = 1;
        while (files.some((file) => file.name === uniqueName)) {
            const fileExtension = name.substring(name.lastIndexOf("."));
            const baseName = name.substring(0, name.lastIndexOf("."));
            uniqueName = `${baseName}-${counter}${fileExtension}`;
            counter++;
        }
        return uniqueName;
    };

    const onDrop = (acceptedFiles) => {
        const filteredFiles = acceptedFiles.filter(
            (file) => file.type === "image/png" || file.type === "image/jpeg"
        );
        const updatedFiles = filteredFiles.map((file) => {
            const uniqueName = generateUniqueFileName(file.name);
            // Create a new object for each file
            return {
                file, // Keep the original File object
                preview: URL.createObjectURL(file),
                name: uniqueName,
            };
        });
        setFiles((prevFiles) => [...prevFiles, ...updatedFiles]);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            "image/png": [".png"],
            "image/jpeg": [".jpeg", ".jpg"],
        },
    });

    const handleDelete = (index) => {
        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    const handleDownload = (file) => {
        const link = document.createElement("a");
        link.href = file.preview;
        link.download = file.name;
        link.click();
    };

    const validateForm = () => {
        const errors = {};

        // Required field checks
        if (!formData.projectName) errors.projectName = "Project name required";
        if (!formData.productName) errors.productName = "Product name required";
        if (!formData.projectReference)
            errors.projectReference = "Project reference required";
        if (!formData.installerName)
            errors.installerName = "Installer name required";
        if (!formData.installationLocation)
            errors.installationLocation = "Installation location required";
        if (!formData.siteOwner) errors.siteOwner = "Site owner required";
        if (!formData.contactNumber)
            errors.contactNumber = "Contact number required";
        if (!formData.email) errors.email = "Email required";
        if (!formData.mailingAddress)
            errors.mailingAddress = "Mailing address required";
        if (!installationDate)
            errors.installationDate = "Installation date required";
        if (files.length === 0) errors.files = "At least one photo required";

        // Email format check
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (formData.email && !emailRegex.test(formData.email)) {
            errors.email = "Invalid email format";
        }

        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validateForm();
        setFormErrors(errors);
        if (Object.keys(errors).length > 0) return;

        setIsSubmitting(true);
        const formDataToSend = new FormData();

        try {
            // Add files array
            files.forEach(({ file }) => {
                if (file.size > 5 * 1024 * 1024) {
                    throw new Error(`File ${file.name} exceeds 5MB limit`);
                }
                formDataToSend.append("files", file);
            });

            // Add form details as JSON string
            const details = {
                projectName: formData.projectName,
                productName: formData.productName,
                projectReference: formData.projectReference,
                installationDate: installationDate?.toISOString(),
                installerName: formData.installerName,
                installationLocation: formData.installationLocation,
                siteOwner: formData.siteOwner,
                contactNumber: formData.contactNumber,
                email: formData.email,
                mailingAddress: formData.mailingAddress,
                consent: formData.consent,
            };

            formDataToSend.append("details", JSON.stringify(details));

            const response = await apiClient.post(
                `/api/projectuser/form/submit/${token}`,
                formDataToSend,
                {
                    headers: { "Content-Type": "multipart/form-data" },
                }
            );

            if (response.status === 200 || response.status === 201) {
                setSnackbar({
                    open: true,
                    message: "Warranty registration submitted successfully!",
                    severity: "success",
                });
                setSuccessMessage(
                    "Warranty registration submitted successfully!"
                );
                setServerError("");
                setFormErrors({});
                // setPhotoError("");
                setFormData({
                    projectName: "",
                    productName: "",
                    projectReference: "",
                    installerName: "",
                    installationLocation: "",
                    siteOwner: "",
                    contactNumber: "",
                    email: "",
                    mailingAddress: "",
                    consent: false,
                });
                setInstallationDate(null);
                setFiles([]);
                setChecklistComplete(false);
            }
        } catch (error) {
            setFormErrors({ submit: error.message });
            setServerError(
                error.response?.data?.message || "Failed to submit form"
            );

            setSnackbar({
                open: true,
                message:
                    error.response?.data?.message || "Failed to submit form",
                severity: "error",
            });

            if (error.response) {
                console.error("Server error:", error.response.data);
            } else if (error.request) {
                console.error("No response from server");
            } else {
                console.error("Request error:", error.message);
            }
        } finally {
            setIsSubmitting(false);
        }
    };
    const productNames = [
        "TRAP_IT",
        "ECOPROTECTOR",
        "Tornado_GPT",
        "HYDROPROTECTOR",
        "STORMBRAKE",
        "DRAINSHIELD",
        "XTREAMFILTER",
        "ENVIROPROTECTOR",
        "ENVIROSAVE",
        "STORMPROTECTOR",
        "HYDROVAULT",
        "PACKAGED_PUMP_STATIONS",
        "None",
    ];
    if (!token || !isValidToken) {
        return (
            <Container maxWidth="sm" sx={{ mt: 4 }}>
                <Alert severity="error" variant="filled">
                    <AlertTitle>Error</AlertTitle>
                    {error || "Invalid token"}
                </Alert>
            </Container>
        );
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Container maxWidth="md" sx={{ py: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Warranty Registration Form
                </Typography>
                <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 4 }}
                >
                    We collect this information to verify warranty eligibility,
                    provide accurate service at the installation site, and
                    ensure seamless communication with the site owner or
                    purchaser. This helps us deliver reliable support, comply
                    with warranty standards, and enhance your overall
                    experience. Your data is secure and used only for these
                    purposes.
                </Typography>

                <Box component="form" noValidate onSubmit={handleSubmit}>
                    <Typography variant="h6" gutterBottom>
                        Project Details Submission
                    </Typography>

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                error={!!formErrors.projectName}
                                helperText={formErrors.projectName}
                                name="projectName"
                                value={formData.projectName}
                                onChange={handleChange}
                                required
                                fullWidth
                                label="Project Name"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth required>
                                <InputLabel id="product-name-label">
                                    Product Name/Model
                                </InputLabel>
                                <Select
                                    name="productName"
                                    value={formData.productName}
                                    onChange={handleChange}
                                    labelId="product-name-label"
                                    label="Product Name/Model"
                                >
                                    {productNames.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={!!formErrors.projectReference}
                                helperText={formErrors.projectReference}
                                name="projectReference"
                                value={formData.projectReference}
                                onChange={handleChange}
                                required
                                fullWidth
                                label="Project Reference"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DatePicker
                                label="Installation Date"
                                value={installationDate}
                                onChange={setInstallationDate}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        required: true,
                                        variant: "outlined",
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={!!formErrors.installerName}
                                helperText={formErrors.installerName}
                                name="installerName"
                                value={formData.installerName}
                                onChange={handleChange}
                                required
                                fullWidth
                                label="Installer Name/Company"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={!!formErrors.installationLocation}
                                helperText={formErrors.installationLocation}
                                name="installationLocation"
                                value={formData.installationLocation}
                                onChange={handleChange}
                                required
                                fullWidth
                                label="Installation Location (Site Address)"
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>

                    <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                        Installation and maintenance Downloads
                    </Typography>

                    <Grid container spacing={2} sx={{ mb: 4 }}>
                        <Grid item xs={12} sm={4}>
                            <a
                                href="/pdfs/EcoProtector Installation 02.pdf"
                                download="EcoProtector Installation 02.pdf"
                                style={{ textDecoration: "none" }}
                            >
                                <Box
                                    component="img"
                                    src="/imgs/Installation.png"
                                    alt="Placeholder Image"
                                    sx={{
                                        width: "100%", // Responsive width
                                        maxWidth: 400, // Max width
                                        height: "auto", // Maintain aspect ratio
                                        borderRadius: 2, // Rounded corners
                                        boxShadow: 3, // Add shadow
                                    }}
                                />
                            </a>
                            <Typography
                                variant="body2"
                                gutterBottom
                                sx={{
                                    mt: 1,
                                    textAlign: "center",
                                    fontSize: 20,
                                }}
                            >
                                EcoProtector Installation
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <a
                                href="/pdfs/EcoProtector Installation 02.pdf"
                                download="EcoProtector Installation 02.pdf"
                                style={{ textDecoration: "none" }}
                            >
                                <Box
                                    component="img"
                                    src="/imgs/Installation.png"
                                    alt="Placeholder Image"
                                    sx={{
                                        width: "100%", // Responsive width
                                        maxWidth: 400, // Max width
                                        height: "auto", // Maintain aspect ratio
                                        borderRadius: 2, // Rounded corners
                                        boxShadow: 3, // Add shadow
                                    }}
                                />
                            </a>

                            <Typography
                                variant="body2"
                                gutterBottom
                                sx={{
                                    mt: 1,
                                    textAlign: "center",
                                    fontSize: 20,
                                }}
                            >
                                EcoProtector Maintenance
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <a
                                href="/pdfs/EcoProtector Installation 02.pdf"
                                download="EcoProtector Installation 02.pdf"
                                style={{ textDecoration: "none" }}
                            >
                                <Box
                                    component="img"
                                    src="/imgs/Installation.png"
                                    alt="Placeholder Image"
                                    sx={{
                                        width: "100%", // Responsive width
                                        maxWidth: 400, // Max width
                                        height: "auto", // Maintain aspect ratio
                                        borderRadius: 2, // Rounded corners
                                        boxShadow: 3, // Add shadow
                                    }}
                                />
                            </a>

                            <Typography
                                variant="body2"
                                gutterBottom
                                sx={{
                                    mt: 1,
                                    textAlign: "center",
                                    fontSize: 20,
                                }}
                            >
                                EcoProtector Checklist
                            </Typography>
                        </Grid>
                    </Grid>

                    <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                            cursor: "pointer",
                            textDecoration: "underline",
                            color: "blue",
                        }}
                        onClick={() => setDialogOpen(true)}
                    >
                        Complete Online Checklist
                    </Typography>

                    <ChecklistDialog
                        open={dialogOpen}
                        onClose={() => setDialogOpen(false)}
                        setAllChecked={setChecklistComplete}
                    />

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                error={!!formErrors.siteOwner}
                                helperText={formErrors.siteOwner}
                                name="siteOwner"
                                value={formData.siteOwner}
                                onChange={handleChange}
                                required
                                fullWidth
                                label="Site Owner or Strata Manager"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={!!formErrors.contactNumber}
                                helperText={formErrors.contactNumber}
                                name="contactNumber"
                                value={formData.contactNumber}
                                onChange={handleChange}
                                required
                                fullWidth
                                label="Contact Number"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={!!formErrors.email}
                                helperText={formErrors.email}
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                fullWidth
                                type="email"
                                label="Email"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={!!formErrors.mailingAddress}
                                helperText={formErrors.mailingAddress}
                                name="mailingAddress"
                                value={formData.mailingAddress}
                                onChange={handleChange}
                                required
                                fullWidth
                                label="Mailing Address"
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>

                    <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                        Photos
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        gutterBottom
                    >
                        Uploaded: {files.length} photos
                    </Typography>
                    <Box
                        {...getRootProps()}
                        sx={{
                            border: "2px dashed #ccc",
                            borderRadius: 1,
                            p: 3,
                            textAlign: "center",
                            mt: 2,
                            mb: 4,
                            cursor: "pointer",
                            backgroundColor: isDragActive
                                ? "#f0f0f0"
                                : "inherit",
                        }}
                    >
                        <input {...getInputProps()} />
                        <AddPhotoAlternateIcon
                            sx={{ fontSize: 40, color: "#4caf50" }}
                        />
                        <Typography>
                            Add photos or drag and drop (.png, .jpeg)
                        </Typography>
                    </Box>

                    {/* Display file list */}
                    <List>
                        {files.map(({ name, preview }, index) => (
                            <ListItem key={index} divider>
                                <ListItemText
                                    primary={
                                        <Typography
                                            sx={{
                                                cursor: "pointer",
                                                textDecoration: "underline",
                                            }}
                                            onClick={() =>
                                                handleDownload({
                                                    name,
                                                    preview,
                                                })
                                            }
                                        >
                                            {name}
                                        </Typography>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => handleDelete(index)}
                                    >
                                        <DeleteIcon color="error" />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>

                    <FormControlLabel
                        control={
                            <Checkbox
                                name="consent"
                                checked={formData.consent}
                                onChange={handleChange}
                            />
                        }
                        label="I consent to the use of my personal data for warranty purposes and future communication regarding product servicing."
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        onClick={(e) => {
                            if (!checklistComplete) {
                                e.preventDefault();
                                setSnackbar({
                                    open: true,
                                    message:
                                        "The check list must be completed before submitting the form",
                                    severity: "warning",
                                });
                            }
                        }}
                        disabled={
                            !formData.consent ||
                            !checklistComplete ||
                            isSubmitting
                        }
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {isSubmitting ? "Submitting..." : "SUBMIT"}
                    </Button>
                </Box>
            </Container>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbar.severity}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </LocalizationProvider>
    );
}
