import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import {
    Box,
    Typography,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Button,
} from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";

const PlayPage2 = () => {
    const [files, setFiles] = useState([]);

    // Helper function to ensure unique file names
    const generateUniqueFileName = (name) => {
        let uniqueName = name;
        let counter = 1;
        while (files.some((file) => file.name === uniqueName)) {
            const fileExtension = name.substring(name.lastIndexOf("."));
            const baseName = name.substring(0, name.lastIndexOf("."));
            uniqueName = `${baseName}-${counter}${fileExtension}`;
            counter++;
        }
        return uniqueName;
    };

    const onDrop = (acceptedFiles) => {
        const filteredFiles = acceptedFiles.filter(
            (file) => file.type === "image/png" || file.type === "image/jpeg"
        );
        const updatedFiles = filteredFiles.map((file) => {
            const uniqueName = generateUniqueFileName(file.name);
            // Create a new object for each file
            return {
                file, // Keep the original File object
                preview: URL.createObjectURL(file),
                name: uniqueName,
            };
        });
        setFiles((prevFiles) => [...prevFiles, ...updatedFiles]);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            "image/png": [".png"],
            "image/jpeg": [".jpeg", ".jpg"],
        },
    });

    const handleDelete = (index) => {
        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    const handleDownload = (file) => {
        const link = document.createElement("a");
        link.href = file.preview;
        link.download = file.name;
        link.click();
    };

    const handleUpload = async () => {
        const formData = new FormData();
        files.forEach(({ file }) => {
            formData.append("files", file);
        });

        try {
            // Replace the URL with your actual backend endpoint
            const response = await axios.post(
                "https://dummy-endpoint.com/upload",
                formData,
                {
                    headers: { "Content-Type": "multipart/form-data" },
                }
            );
            alert("Files uploaded successfully!");
            console.log(response.data);
        } catch (error) {
            console.error("Error uploading files:", error);
            alert("Failed to upload files.");
        }
    };

    return (
        <Box>
            <Typography variant="h6" gutterBottom>
                Photos
            </Typography>
            <Typography variant="body2" color="textSecondary">
                Uploaded {files.length} files.
            </Typography>
            <Box
                {...getRootProps()}
                sx={{
                    border: "2px dashed #4caf50",
                    borderRadius: "8px",
                    padding: "16px",
                    textAlign: "center",
                    marginTop: "16px",
                    cursor: "pointer",
                    backgroundColor: isDragActive ? "#f0f0f0" : "inherit",
                }}
            >
                <input {...getInputProps()} />
                <AddPhotoAlternateIcon
                    sx={{ fontSize: 40, color: "#4caf50" }}
                />
                <Typography>
                    Add photos or drag and drop (.png, .jpeg)
                </Typography>
            </Box>

            {/* Display file titles */}
            <List sx={{ marginTop: "16px" }}>
                {files.map(({ name, preview }, index) => (
                    <ListItem key={index} divider>
                        <ListItemText
                            primary={
                                <Typography
                                    sx={{
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                    }}
                                    onClick={() =>
                                        handleDownload({ name, preview })
                                    }
                                >
                                    {name}
                                </Typography>
                            }
                        />
                        <ListItemSecondaryAction>
                            <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => handleDelete(index)}
                            >
                                <DeleteIcon color="error" />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>

            {files.length > 0 && (
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: "16px" }}
                    onClick={handleUpload}
                >
                    Upload Files
                </Button>
            )}
        </Box>
    );
};

export default PlayPage2;
